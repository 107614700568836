<template>
  <div class="container-fluid">
    <div class="card border-0 shadow-none">
      <div class="card-body">
        <h1 class="fw-bold text-uppercase text-primary">Marketing Suite</h1>
        <div class="row mb-2">
          <div class="col-md-8">
            <p class="text-dark">
              Welcome to the marketing suite, from here you can manage your
              lists, email templates and send-outs.
            </p>
          </div>
        </div>

        <!--  -->
        <h4 class="fw-bold text-primary mb-4">Email Marketing</h4>

        <div class="row mb-5">
          <div class="col-md-4">
            <ul class="list-group list-group-flush">
              <li class="list-group-item text-primary p-3 mb-3 shadow-sm">
                <div class="row">
                  <div class="col my-auto">
                    <p class="fw-bold mb-0">Custom Domains</p>
                  </div>
                  <div class="col-auto ms-auto">
                    <span
                      class="badge badge-pill bg-primary"
                      v-if="domains.length > 0"
                      >Complete</span
                    >
                    <span class="badge badge-pill bg-warning" v-else
                      >Requires Setup</span
                    >
                  </div>
                </div>
              </li>

              <li class="list-group-item text-primary p-3 mb-3 shadow-sm">
                <div class="row">
                  <div class="col my-auto">
                    <p class="fw-bold mb-0">Marketing Lists</p>
                  </div>
                  <div class="col-auto ms-auto">
                    <span
                      class="badge badge-pill bg-primary"
                      v-if="lists.length"
                      >{{ lists.length }} ready for use</span
                    >
                    <span class="badge badge-pill bg-warning" v-else
                      >Required for sendouts</span
                    >
                  </div>
                </div>
              </li>
              <li class="list-group-item text-primary p-3 mb-3 shadow-sm">
                <div class="row">
                  <div class="col my-auto">
                    <p class="fw-bold mb-0">Email Templates</p>
                  </div>
                  <div class="col-auto ms-auto">
                    <span
                      class="badge badge-pill bg-primary"
                      v-if="templates.length"
                      >{{ templates.length }} ready for use</span
                    >
                    <span class="badge badge-pill bg-warning" v-else
                      >Required for sendouts</span
                    >
                  </div>
                </div>
              </li>
              <li class="list-group-item text-primary p-3 mb-3 shadow-sm">
                <div class="row">
                  <div class="col my-auto">
                    <p class="fw-bold mb-0">Marketing Send-outs</p>
                  </div>

                  <div class="col-auto ms-auto">
                    <span
                      class="badge badge-pill bg-primary"
                      v-if="sendouts.length"
                      >{{ sendouts.length }} active</span
                    >
                    <span class="badge badge-pill bg-warning" v-else
                      >Create your first</span
                    >
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="col">
            <div class="card border-0 text-center shadow-sm bg-light">
              <div class="card-body">
                <h4 class="fw-bold text-primary mb-4">Marketing Lists</h4>
                <router-link
                  to="/marketing-lists"
                  class="btn btn-outline-primary"
                >
                  <i class="far fa-list me-2"></i>Go to Lists
                </router-link>
              </div>
            </div>
          </div>

          <div class="col">
            <div class="card border-0 text-center shadow-sm bg-light">
              <div class="card-body">
                <h4 class="fw-bold text-primary mb-4">Email Templates</h4>
                <router-link
                  to="/marketing-email-templates"
                  class="btn btn-outline-primary"
                >
                  <i class="far fa-file-alt me-2"></i>Go to Templates
                </router-link>
              </div>
            </div>
          </div>

          <div class="col">
            <div class="card border-0 text-center shadow-sm bg-light">
              <div class="card-body">
                <h4 class="fw-bold text-primary mb-4">Marketing Sendouts</h4>
                <router-link
                  to="/marketing-sendouts"
                  class="btn btn-outline-primary"
                >
                  <i class="far fa-megaphone me-2"></i>Go to Send-outs
                </router-link>
              </div>
            </div>
          </div>
        </div>

        <!--  -->
        <h4 class="fw-bold text-primary mb-4">SMS Marketing (coming soon)</h4>
        <div class="row mb-4">
          <div class="col-md-4">
            <ul class="list-group list-group-flush">
              <li class="list-group-item text-primary p-3 mb-3 shadow-sm">
                <div class="row">
                  <div class="col my-auto">
                    <p class="fw-bold mb-0">Phone Numbers</p>
                  </div>
                  <div class="col-auto ms-auto">
                    <span
                      class="badge badge-pill bg-primary"
                      v-if="numbers.length"
                      >{{ numbers.length }} active</span
                    >
                    <span class="badge badge-pill bg-warning" v-else
                      >Required for sendouts</span
                    >
                  </div>
                </div>
              </li>
              <li class="list-group-item text-primary p-3 mb-3 shadow-sm">
                <div class="row">
                  <div class="col my-auto">
                    <p class="fw-bold mb-0">SMS Templates</p>
                  </div>
                  <div class="col-auto ms-auto">
                    <span
                      class="badge badge-pill bg-primary"
                      v-if="smsTemplates.length"
                      >{{ smsTemplates.length }} ready for use</span
                    >
                    <span class="badge badge-pill bg-warning" v-else
                      >Required for sendouts</span
                    >
                  </div>
                </div>
              </li>
              <li class="list-group-item text-primary p-3 mb-3 shadow-sm">
                <div class="row">
                  <div class="col my-auto">
                    <p class="fw-bold mb-0">SMS Send-outs</p>
                  </div>
                  <div class="col-auto ms-auto">
                    <span
                      class="badge badge-pill bg-primary"
                      v-if="smsSendouts.length"
                      >{{ smsSendouts.length }} active sendouts</span
                    >
                    <span class="badge badge-pill bg-warning" v-else
                      >Create your first</span
                    >
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="col">
            <div class="card border-0 text-center shadow-sm bg-light">
              <div class="card-body">
                <h4 class="fw-bold text-primary mb-4">Phone Numbers</h4>
                <router-link
                  to="/phone-numbers"
                  class="btn btn-outline-primary disabled"
                >
                  <i class="far fa-phone me-2"></i>Go to Numbers
                </router-link>
              </div>
            </div>
          </div>

          <div class="col">
            <div class="card border-0 text-center shadow-sm bg-light">
              <div class="card-body">
                <h4 class="fw-bold text-primary mb-4">SMS Templates</h4>
                <router-link
                  to="/marketing-sms-templates"
                  class="btn btn-outline-primary disabled"
                >
                  <i class="far fa-file-alt me-2"></i>Go to Templates
                </router-link>
              </div>
            </div>
          </div>

          <div class="col">
            <div class="card border-0 text-center shadow-sm bg-light">
              <div class="card-body">
                <h4 class="fw-bold text-primary mb-4">SMS Sendouts</h4>
                <router-link
                  to="/marketing-sendouts"
                  class="btn btn-outline-primary disabled"
                >
                  <i class="far fa-megaphone me-2"></i>Go to Send-outs
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      domains: [],
      lists: [],
      templates: [],
      sendouts: [],
      numbers: [],
      smsTemplates: [],
      smsSendouts: [],
    };
  },
  methods: {
    async fetchDomains() {
      const {
        data: { data },
      } = await this.$axios.get(process.env.VUE_APP_API_URL + "/domains");
      this.domains = data;
    },
    async fetchLists() {
      const { data } = await this.$axios.get(
        process.env.VUE_APP_API_URL + "/marketing-lists"
      );
      this.lists = data;
    },
    async fetchTemplates() {
      const { data } = await this.$axios.get(
        process.env.VUE_APP_API_URL + "/marketing-email-templates"
      );
      this.templates = data;
    },
    async fetchSendouts() {
      const { data } = await this.$axios.get(
        process.env.VUE_APP_API_URL + "/marketing-sendouts"
      );
      this.sendouts = data;
    },
  },
  created() {
    this.fetchDomains();
    this.fetchLists();
    this.fetchTemplates();
    this.fetchSendouts();
  },
};
</script>

<style>
</style>